import Vue from "vue";
import AppDropDownList from "./AppDropDownList.vue";
export var EmployeeCalendarTypeVisibility;
(function (EmployeeCalendarTypeVisibility) {
    EmployeeCalendarTypeVisibility[EmployeeCalendarTypeVisibility["Never"] = 0] = "Never";
    EmployeeCalendarTypeVisibility[EmployeeCalendarTypeVisibility["WhenHaveAppointmentInRoom"] = 1] = "WhenHaveAppointmentInRoom";
    EmployeeCalendarTypeVisibility[EmployeeCalendarTypeVisibility["WhenHaveAppointments"] = 2] = "WhenHaveAppointments";
    EmployeeCalendarTypeVisibility[EmployeeCalendarTypeVisibility["WhenIsAvailable"] = 3] = "WhenIsAvailable";
    EmployeeCalendarTypeVisibility[EmployeeCalendarTypeVisibility["Always"] = 4] = "Always";
})(EmployeeCalendarTypeVisibility || (EmployeeCalendarTypeVisibility = {}));
export function getEmployeeCalendarTypeVisibilityDescription(visibility, vue) {
    switch (visibility) {
        case EmployeeCalendarTypeVisibility.Never:
            return vue.$t("calendarTypes.visibility.never");
        case EmployeeCalendarTypeVisibility.WhenHaveAppointmentInRoom:
            return vue.$t("calendarTypes.visibility.whenHaveAppointmentInRoom");
        case EmployeeCalendarTypeVisibility.WhenHaveAppointments:
            return vue.$t("calendarTypes.visibility.whenHaveAppointments");
        case EmployeeCalendarTypeVisibility.WhenIsAvailable:
            return vue.$t("calendarTypes.visibility.whenIsAvailable");
        case EmployeeCalendarTypeVisibility.Always:
            return vue.$t("calendarTypes.visibility.always");
        default:
            throw Error(`The visibility type ${visibility} is not managed.`);
    }
}
export default Vue.extend({
    components: {
        "app-dropdownlist": AppDropDownList
    },
    props: {
        id: {
            type: String,
            required: true
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true
        },
        placeholder: {
            type: String,
            required: false
        },
        value: {
            type: [String, Boolean, Number],
            required: false
        },
        cssClass: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            source: [
                {
                    text: getEmployeeCalendarTypeVisibilityDescription(EmployeeCalendarTypeVisibility.Never, this),
                    value: EmployeeCalendarTypeVisibility.Never
                },
                {
                    text: getEmployeeCalendarTypeVisibilityDescription(EmployeeCalendarTypeVisibility.WhenHaveAppointmentInRoom, this),
                    value: EmployeeCalendarTypeVisibility.WhenHaveAppointmentInRoom
                },
                {
                    text: getEmployeeCalendarTypeVisibilityDescription(EmployeeCalendarTypeVisibility.WhenHaveAppointments, this),
                    value: EmployeeCalendarTypeVisibility.WhenHaveAppointments
                },
                {
                    text: getEmployeeCalendarTypeVisibilityDescription(EmployeeCalendarTypeVisibility.WhenIsAvailable, this),
                    value: EmployeeCalendarTypeVisibility.WhenIsAvailable
                },
                {
                    text: getEmployeeCalendarTypeVisibilityDescription(EmployeeCalendarTypeVisibility.Always, this),
                    value: EmployeeCalendarTypeVisibility.Always
                },
            ],
            fields: { text: "text", value: "value" }
        };
    },
    methods: {
        emitValue(value) {
            this.$emit("input", value);
        }
    }
});
