import Vue from "vue";
import AppDropDownListEmployeeCalendarTypeVisibility, { EmployeeCalendarTypeVisibility } from "@/components/Inputs/AppDropDownListEmployeeCalendarTypeVisibility.vue";
export default Vue.extend({
    components: {
        'app-dropdownlist-employee-calendar-type-visibility': AppDropDownListEmployeeCalendarTypeVisibility
    },
    data() {
        return {
            data: {
                employeeId: undefined,
                visibility: undefined
            },
            employees: []
        };
    },
    async created() {
        if (this.data.visibility == null)
            this.data.visibility = EmployeeCalendarTypeVisibility.WhenIsAvailable;
        this.$options.employeeId = this.data.employeeId?.toString() ?? "0";
        this.employees = await this.$store.dispatch("employee/getAll", {
            load: false
        });
        this.employees = this.employees.filter(employee => employee.deletedAt == undefined || employee.id == this.data.employeeId);
        for (const employee of this.employees) {
            employee.fullName = `${employee.name} ${employee.surname}`;
        }
    }
});
