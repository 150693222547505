import Vue from "vue";
import { GridPlugin, Edit, Sort, Toolbar, CommandColumn } from "@syncfusion/ej2-vue-grids";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import RoomEditTemplate from "@/components/Templates/CalendarType/RoomEditTemplate.vue";
import EmployeeEditTemplate from "@/components/Templates/CalendarType/EmployeeEditTemplate.vue";
import { getEmployeeCalendarTypeVisibilityDescription } from "@/components/Inputs/AppDropDownListEmployeeCalendarTypeVisibility.vue";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("calendarTypes.edit.title"),
            id: this.$route.params.id,
            employeesGrid: {
                editSettings: {
                    allowEditing: true,
                    allowAdding: true,
                    allowDeleting: true,
                    mode: "Dialog",
                    template: function () {
                        return {
                            template: Vue.component("calendar-type-employee-item", EmployeeEditTemplate)
                        };
                    }
                },
                commands: [
                    /*
                    {
                      type: "Edit",
                      buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                    },
                    */
                    {
                        type: "Delete",
                        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                    }
                ],
                sortOptions: {
                    columns: [{ field: "employeeFullName", direction: "Ascending" }]
                },
                toolbar: ["Add"],
                validationOptions: {
                    rules: {
                        employeeId: {
                            required: true
                        },
                        visibility: {
                            required: true
                        }
                    }
                }
            },
            roomsGrid: {
                editSettings: {
                    allowEditing: true,
                    allowAdding: true,
                    allowDeleting: true,
                    mode: "Dialog",
                    template: function () {
                        // Get office drop down list
                        const element = document.getElementById('officeId');
                        const dropDownList = element.ej2_instances[0];
                        return {
                            template: {
                                extends: RoomEditTemplate,
                                propsData: { officeId: dropDownList.value }
                            }
                            /*
                            template: Vue.component(
                              "calendar-type-room-item",
                              RoomEditTemplate
                            )
                             */
                        };
                    }
                },
                commands: [
                    /*
                    {
                      type: "Edit",
                      buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                    },
                    */
                    {
                        type: "Delete",
                        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                    }
                ],
                sortOptions: {
                    columns: [{ field: "roomName", direction: "Ascending" }]
                },
                toolbar: ["Add"],
                validationOptions: {
                    rules: {
                        roomId: {
                            required: true
                        },
                        officeId: {
                            required: true
                        }
                    }
                }
            },
            calendarType: {},
            offices: [],
            validationOptions: {
                rules: {
                    officeId: {
                        required: true
                    },
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    },
                    description: {
                        maxLength: 500
                    }
                }
            }
        };
    },
    provide: {
        grid: [Sort, Edit, Toolbar, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        edit() {
            return this.$store.dispatch("calendarType/edit", {
                id: this.id,
                calendarType: this.calendarType
            });
        },
        editSuccess() {
            this.$toast.showSuccessToast(this.$t("calendarTypes.edit.toastSuccessTitle"), this.$t("calendarTypes.edit.toastSuccessContent", {
                name: this.calendarType.name
            }));
            return this.$router.push("/administration/calendar-types");
        },
        cancel() {
            return this.$router.push("/administration/calendar-types");
        },
        employeesGridActionBegin(args) {
            if (args.requestType === "save") {
                args.data["employeeFullName"] = args.form.querySelector("#employeeId").value;
                args.data["visibilityName"] = args.form.querySelector("#visibility").value;
            }
        },
        employeesGridActionComplete(args) {
            if (args.requestType === "beginEdit" || args.requestType === "add") {
                // Add Validation Rules
                const calendarTypeEmployees = this.calendarType.employees;
                args.form.ej2_instances[0].addRules("employeeId", {
                    exists: [
                        function (args) {
                            const employeeIdOld = args.element.form.querySelector("#employeeIdOld").value;
                            if (args.value == employeeIdOld)
                                return true;
                            return !calendarTypeEmployees.some(employee => employee.employeeId == args.value);
                        },
                        this.$t("calendarTypes.employeeExists")
                    ]
                });
                args.dialog.width = 380;
                if (args.requestType === "add") {
                    args.dialog.header = this.$t("calendarTypes.newEmployee");
                }
                else {
                    args.dialog.header = this.$t("calendarTypes.editEmployee", {
                        name: args.rowData["employeeFullName"]
                    });
                }
            }
        },
        onEmployeesGridRowDataBound(args) {
            if (args.data.deletedAt != null)
                args.row.style.visibility = "collapse";
        },
        roomsGridActionBegin(args) {
            if (args.requestType === "save") {
                args.data["roomName"] = args.form.querySelector("#roomId").value;
            }
        },
        roomsGridActionComplete(args) {
            if (args.requestType === "beginEdit" || args.requestType === "add") {
                // Add Validation Rules
                const calendarTypeRooms = this.calendarType.rooms;
                args.form.ej2_instances[0].addRules("roomId", {
                    exists: [
                        function (args) {
                            const roomIdOld = args.element.form.querySelector("#roomIdOld").value;
                            if (args.value == roomIdOld)
                                return true;
                            return !calendarTypeRooms.some(room => room.roomId == args.value);
                        },
                        this.$t("employees.roomExists")
                    ]
                });
                args.dialog.width = 380;
                if (args.requestType === "add") {
                    args.dialog.header = this.$t("calendarTypes.newRoom");
                }
                else {
                    args.dialog.header = this.$t("calendarTypes.editRoom", {
                        name: args.rowData["roomName"]
                    });
                }
            }
        },
        onRoomsGridRowDataBound(args) {
            if (args.data.deletedAt != null)
                args.row.style.visibility = "collapse";
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll");
            const employees = await this.$store.dispatch("employee/getAll", {
                load: true
            });
            const rooms = await this.$store.dispatch("room/getAll", {
                load: true
            });
            this.calendarType = await this.$store.dispatch("calendarType/get", {
                id: this.id
            });
            for (const calendarTypeEmployee of this.calendarType.employees) {
                const employee = employees.find(employee => employee.id === calendarTypeEmployee.employeeId);
                if (employee != undefined)
                    calendarTypeEmployee.employeeFullName = `${employee.name} ${employee.surname}`;
                calendarTypeEmployee.visibilityName = getEmployeeCalendarTypeVisibilityDescription(calendarTypeEmployee.visibility, this);
            }
            for (const calendarTypeRoom of this.calendarType.rooms) {
                const room = rooms.find(room => room.id === calendarTypeRoom.roomId);
                if (room != undefined)
                    calendarTypeRoom.roomName = room.name;
            }
            // Refresh grids to show updated data
            this.$refs.calendarTypeEmployeesGrid.ej2Instances.refresh();
            this.$refs.calendarTypeRoomsGrid.ej2Instances.refresh();
            //this.title += ` ${this.calendarType.name}`;
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/administration/calendar-types");
        }
        finally {
            this.hideSpinner();
        }
    }
});
