import Vue from "vue";
export default Vue.extend({
    props: {
        officeId: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            data: {
                roomId: undefined
            },
            rooms: []
        };
    },
    async created() {
        this.$options.roomId = this.data.roomId?.toString() ?? "0";
        this.rooms = await this.$store.dispatch("room/getAll", {
            load: false
        });
        this.rooms = this.rooms.filter(room => room.officeId === this.officeId && (room.deletedAt == undefined || room.id === this.data.roomId));
    }
});
